.container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-style: preserve-3d;
    perspective: 2000px;
    transform: rotateX(-30deg) rotateY(-45deg);
  }
  
  .holder {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-style: preserve-3d;
    transform: translate3d(0em, 3em, 1.5em);
  }
  .holder:last-child {
    transform: rotateY(-90deg) rotateX(90deg) translate3d(0, 3em, 1.5em);
  }
  .holder:first-child {
    transform: rotateZ(-90deg) rotateX(-90deg) translate3d(0, 3em, 1.5em);
  }
  .holder:nth-child(1) .box {
    background-color: #1fbcd3;
  }
  .holder:nth-child(1) .box:before {
    background-color: #126d7a;
  }
  .holder:nth-child(1) .box:after {
    background-color: #1894a7;
  }
  .holder:nth-child(2) .box {
    background-color: #cbe2b4;
  }
  .holder:nth-child(2) .box:before {
    background-color: #98c66a;
  }
  .holder:nth-child(2) .box:after {
    background-color: #b2d48f;
  }
  .holder:nth-child(3) .box {
    background-color: #f6b6ca;
  }
  .holder:nth-child(3) .box:before {
    background-color: #eb5b88;
  }
  .holder:nth-child(3) .box:after {
    background-color: #f089a9;
  }
  
  .box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-style: preserve-3d;
    animation: ani-box 6s infinite;
    width: 3em;
    height: 3em;
  }
  .box:before,
  .box:after {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
  }
  .box:before {
    left: 100%;
    bottom: 0;
    transform: rotateY(90deg);
    transform-origin: 0 50%;
  }
  .box:after {
    left: 0;
    bottom: 100%;
    transform: rotateX(90deg);
    transform-origin: 0 100%;
  }
  
  @keyframes ani-box {
    8.33% {
      transform: translate3d(-50%, -50%, 0) scaleZ(2);
    }
    16.7% {
      transform: translate3d(-50%, -50%, -3em) scaleZ(1);
    }
    25% {
      transform: translate3d(-50%, -100%, -3em) scaleY(2);
    }
    33.3% {
      transform: translate3d(-50%, -150%, -3em) scaleY(1);
    }
    41.7% {
      transform: translate3d(-100%, -150%, -3em) scaleX(2);
    }
    50% {
      transform: translate3d(-150%, -150%, -3em) scaleX(1);
    }
    58.3% {
      transform: translate3d(-150%, -150%, 0) scaleZ(2);
    }
    66.7% {
      transform: translate3d(-150%, -150%, 0) scaleZ(1);
    }
    75% {
      transform: translate3d(-150%, -100%, 0) scaleY(2);
    }
    83.3% {
      transform: translate3d(-150%, -50%, 0) scaleY(1);
    }
    91.7% {
      transform: translate3d(-100%, -50%, 0) scaleX(2);
    }
    100% {
      transform: translate3d(-50%, -50%, 0) scaleX(1);
    }
  }
  